import FlatList from 'flatlist-react'
import { ArrowRight } from 'iconsax-react'
import { isEmpty } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import emptyStateAlternativeAsset from '@/assets/emptyState/emptyStateAlternativeAsset.png'
import emptyProfilePhoto from '@/assets/reportInfo/emptyProfilePhoto.png'
import { BackHeader } from '@/components/BackHeader/BackHeader'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useScreenSize from '@/hooks/useScreenSize'
import {
  useGetSubjectBasicInformationQuery,
  useLazySocialMediaPostQuery,
} from '@/store/api/Person/Person.query'
import type {
  FilterByType,
  PlatformType,
  SocialMediaPost,
} from '@/store/api/Person/Person.types'

import { Header } from '../Components/Header'

import { FlaggedPopover } from './components/FlaggedPopover'
import type { FlaggedPopoverSortType } from './components/PlatformPopover'
import { PlatformPopover } from './components/PlatformPopover'
import { PostCard } from './components/PostCard'

export const PostList = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const plataform: PlatformType | undefined = state.plataform
  const filterBy: FilterByType | undefined = state.filterBy
  const id: string = state.id
  const navigate = useNavigate()
  const isMobile = useMobileMediaQuery()

  const { height: screenHeigth, width: screenWidth } = useScreenSize()
  const [currentPage, setCurrentPage] = useState(1)
  const [sortType, setSortType] = useState<'latest' | 'oldest'>('latest')
  const [sortFlaggType, setSortFlaggType] =
    useState<FlaggedPopoverSortType>(filterBy)
  const { data } = useGetSubjectBasicInformationQuery({
    id,
  })

  const [
    makeRequest,
    {
      data: postData,
      isLoading: isLoadingPostFirstTime,
      isError: isErrorPost,
      isFetching: isFechingPost,
    },
  ] = useLazySocialMediaPostQuery()

  const makeBeRequest = useCallback(
    (page: number, currentFilterBy?: FilterByType) => {
      makeRequest({
        id,
        page: page,
        pageSize: 10,
        plataform,
        filterBy: currentFilterBy,
      })
    },
    [id, plataform, makeRequest],
  )

  useEffect(() => {
    makeBeRequest(currentPage, sortFlaggType)
  }, [])

  const isEmptyArray = isEmpty(postData?.posts.list) || isLoadingPostFirstTime
  const hasMoreData = useMemo(
    () => (postData?.posts.offset && postData?.posts.offset > 0) as boolean,
    [postData],
  )
  const postCardHeigth = useMemo(
    () => Math.floor(screenHeigth / (plataform ? 1.7 : 1.35)),
    [screenHeigth],
  )
  const postItemHeigth = useMemo(
    () => Math.floor(screenHeigth / 2),
    [screenHeigth],
  )

  const renderPost = (post: SocialMediaPost, idx: string) => {
    return (
      <div id={idx}>
        <PostCard post={post} cardHeigth={postItemHeigth} />
      </div>
    )
  }

  const fetchMoreData = (
    page?: number,
    sortFlaggedType?: FlaggedPopoverSortType,
  ) => {
    const newPage = page ?? currentPage + 1
    setCurrentPage(newPage)
    makeBeRequest(newPage, sortFlaggedType)
  }

  const fetchMoreOnPagination = () => {
    const newPage = currentPage + 1
    setCurrentPage(newPage)
    makeBeRequest(newPage, sortFlaggType)
  }

  const isLoading =
    (isFechingPost && currentPage === 1) || isLoadingPostFirstTime

  return (
    <WeScreen
      isError={isErrorPost}
      isLoading={isLoadingPostFirstTime}
      title={''}
      content={
        <div>
          <div className="pb-3">
            <BackHeader onPressBack={() => navigate(-1)} backText={''} />
          </div>
          {plataform && (
            <Card className="flex flex-col gap-8 p-4 bg-baseColorHighlight mb-3">
              <Header
                fullName={postData?.name}
                platform={plataform}
                isPrivate={postData?.isPrivate}
                profileImage={
                  data?.info.profileImgUrl
                    ? data?.info.profileImgUrl
                    : emptyProfilePhoto
                }
                followers={postData?.followers}
                following={postData?.following}
                posts={postData?.postsCount}
                children={
                  plataform === 'WebSearch' ? (
                    <></>
                  ) : (
                    <Button
                      variant="default"
                      asChild
                      onClick={() => {
                        window.open(postData?.profileLink, '_blank')
                      }}
                      role="button"
                      className="lg:w-44 lg:h-12 flex flex-row gap-2 px-5 py-3 rounded-lg justify-center items-center"
                    >
                      <div className="flex items-center">
                        <p className="text-base font-medium tracking-wide">
                          {t('dashboard.header.goToProfile')}
                        </p>
                        <ArrowRight />
                      </div>
                    </Button>
                  )
                }
              />
            </Card>
          )}

          <div className="p-8">
            <div
              className={`flex justify-between mb-8 ${
                isEmptyArray && (plataform || filterBy) && 'hidden'
              }`}
            >
              <p className="text-textNormal text-xl font-medium tracking-wide">
                {plataform && (
                  <>
                    {plataform} {t('dashboard.postList.Interactions')}
                  </>
                )}
                {filterBy && (
                  <>
                    {filterBy} {t('dashboard.postList.posts')}
                  </>
                )}
              </p>

              {!isEmptyArray && filterBy && (
                <FlaggedPopover
                  sortedBy={sortType}
                  onChangeSort={(sortBy) => {
                    setSortType(sortBy)
                  }}
                />
              )}
              {!isEmptyArray && plataform && (
                <PlatformPopover
                  sortedBy={sortFlaggType}
                  onChangeSort={(sortBy) => {
                    setSortFlaggType(sortBy)
                    fetchMoreData(1, sortBy)
                  }}
                />
              )}
            </div>
            {!isLoading && postData && (
              <div
                style={{
                  overflow: 'auto',
                  height: `${postCardHeigth}px`,
                  paddingRight: 16,
                }}
                id="scrolling-container"
              >
                <FlatList
                  list={postData?.posts.list || []}
                  renderItem={renderPost}
                  displayGrid
                  reversed={sortType === 'oldest'}
                  minColumnWidth={
                    isMobile ? `${screenWidth / 2}px` : `${screenWidth / 3.5}px`
                  }
                  renderWhenEmpty={() => (
                    <>
                      <div className="flex flex-col w-full items-end">
                        {plataform && (
                          <PlatformPopover
                            sortedBy={sortFlaggType}
                            onChangeSort={(sortBy) => {
                              setSortFlaggType(sortBy)
                              fetchMoreData(1, sortBy)
                            }}
                          />
                        )}
                      </div>
                      <div className="flex overflow-hidden items-center justify-center">
                        <EmptyState text={t('dashboard.postList.emptyState')} />
                      </div>
                    </>
                  )}
                  pagination={{
                    hasMore: hasMoreData,
                    loadMore: fetchMoreOnPagination,
                  }}
                />
              </div>
            )}
            {isLoading && (
              <div className="flex justify-center ">
                <LottieSpinner />
              </div>
            )}
          </div>
        </div>
      }
    />
  )
}
