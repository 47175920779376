import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '@/components/ui/card'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useAppSelector } from '@/hooks/redux'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import {
  useGetEnterpriseRolesQuery,
  useGetEnterpriseUserQuery,
  useListOfInvitesQuery,
} from '@/store/api/Enterprise/Enterprise.query'
import {
  selectItsDefaultState,
  selectUserInfo,
} from '@/store/api/Users/Users.selector'

import { InviteMembersDialog } from './Components/InviteMembersDialog'
import { InviteMembersItem } from './Components/InviteMembersItem'
import { TeamMembersItem } from './Components/TeamMembersItem'
import { WeScreenV2 } from '@/components/WeScreen/WeScreenV2'
import { divide } from 'ramda'
import { Button } from '@/components/ui/button'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'
import { InfoCircle } from 'iconsax-react'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { TeamLearnMoreSheet } from './Components/TeamLearnMoreSheet'
import { useSelector } from 'react-redux'

export const Team = () => {
  const { t } = useTranslation()
  const { hasHandleUsers } = useRedirectBasedOnPermissions()
  const isDefaultState = useAppSelector(selectItsDefaultState)
  const userInfo = useAppSelector(selectUserInfo)
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isTablet = useTabletMediaQuery()

  const {
    data: enterpriseData,
    isError: isErrorEnterprise,
    isLoading: isLoadingEnterprise,
  } = useGetEnterpriseUserQuery({ status: 'active' })

  const {
    data: rolesData,
    isError: isErrorRoles,
    isLoading: isLoadingRoles,
  } = useGetEnterpriseRolesQuery()

  const {
    data: invitesData,
    isError: isErrorInvites,
    isLoading: isLoadingInvites,
  } = useListOfInvitesQuery({ userId: userInfo.userId })

  useEffect(() => {
    hasHandleUsers()
  }, [isDefaultState])

  const loggedInUser = enterpriseData?.info.find(
    (member: any) =>
      member.email.trim().toLowerCase() === userInfo.email.trim().toLowerCase(),
  )

  const otherMembers =
    enterpriseData?.info.filter(
      (member: any) =>
        member.email.trim().toLowerCase() !==
        userInfo.email.trim().toLowerCase(),
    ) || []

  const sortedEnterpriseData = loggedInUser
    ? [loggedInUser, ...otherMembers]
    : otherMembers

  return (
    <WeScreenV2
      title={`${userInfo.enterpriseName} · ${enterpriseData?.info.length} ${t(
        'dashboard.team.members',
      )}`}
      customHeaderGap={0}
      isLoading={isLoadingEnterprise || isLoadingRoles || isLoadingInvites}
      isError={isErrorEnterprise || isErrorRoles || isErrorInvites}
      useNextButton={false}
      useHeaderBackButton={false}
      rightSideHeaderContent={
        isSmallMobile || isMobile || isTablet ? (
          <TeamLearnMoreSheet />
        ) : (
          <>
            <TeamLearnMoreSheet />
            <InviteMembersDialog availableRoles={rolesData?.info.roles} />
          </>
        )
      }
    >
      {(isSmallMobile || isMobile || isTablet) && (
        <div className="mb-4">
          <InviteMembersDialog availableRoles={rolesData?.info.roles} />
        </div>
      )}
      <div className="md:mt-4">
        {sortedEnterpriseData?.map((member: any) => (
          <TeamMembersItem
            key={member.id}
            name={member.firstname}
            address={member.email}
            role={member.role}
            id={member.id}
            availableRoles={rolesData?.info.roles}
            isLoggedUser={member.email === userInfo.email}
          />
        ))}
        {invitesData?.info.map((inviteMember: any) => {
          if (inviteMember.status !== 'used') {
            return (
              <InviteMembersItem
                email={inviteMember.email}
                role={inviteMember.role}
                name={inviteMember.firstname}
                address={inviteMember.email}
                id={inviteMember.id}
              />
            )
          }
        })}
      </div>
    </WeScreenV2>
  )
}
