import { Menu, Setting2 } from 'iconsax-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Sidebar } from 'react-pro-sidebar'
import { Link, Outlet, useLocation } from 'react-router-dom'

import AdverseMediaCreditIcon from '@/assets/sidebar/adverseMediaCreditIcon.svg?react'
import AiPlusAnalistCreditIcon from '@/assets/sidebar/aiPlusAnalistReportCrediticon.svg?react'
import AiReportCreditIcon from '@/assets/sidebar/aiReportCreditIcon.svg?react'
import FacialMatchingCrediticon from '@/assets/sidebar/facialMatchingCreditIcon.svg?react'
import WeCheckAssistedCreditIcon from '@/assets/sidebar/weCheckAssistedCreditIcon.svg?react'
import backgroundImage1 from '@/assets/wecheckLights/1.png'
import backgroundImage2 from '@/assets/wecheckLights/2.png'
import { Loader } from '@/components/Loader/Loader'
import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'
import env from '@/env/env'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import { useCheckSession } from '@/hooks/useCheckSession'
import { useDisableBasedOnPermissions } from '@/hooks/useDisableBasedOnPermissions'
import { useRudderStack } from '@/hooks/useRudderStack'
import { clearUserCredentials } from '@/store/api/SignUp/SignUp.slice'
import { selectIsNavBarCollapsed } from '@/store/api/ui/ui.selector'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import {
  selectIsPending,
  selectUserFirstLetter,
  selectUserFullname,
  selectAiReportCredits,
  selectAiAnalystReportCredits,
  selectAssistedReportCredits,
  selectFacialMatchingCredits,
  selectAdverseMediaReportCredits,
} from '@/store/api/Users/Users.selector'
import { setUserInfo } from '@/store/api/Users/Users.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { SidebarCredits } from './components/sidebarCredits/SidebarCredits'
import { SidebarItem } from './components/sidebarItem/SidebarItem'
import { SidebarLogout } from './components/sidebarLogout/sidebarLogout'
import { SidebarUser } from './components/sidebarUser/sidebarUser'
import { WecheckEnviromentDistinction } from './components/WecheckEnviromentDistinction/WecheckEnviromentDistinction'

export const LeftSideBar = () => {
  const location = useLocation()
  const locationPathname = location.pathname
  const dispatch = useAppDispatch()
  const enviroment = env.NODE_ENV
  const { t } = useTranslation()

  const isCreateReportFlow =
    locationPathname === AppRoutes.newSearchInitialReportSetup ||
    locationPathname === AppRoutes.newSearchFocusedTopics ||
    locationPathname === AppRoutes.newSearchFormSubjectInfo ||
    locationPathname === AppRoutes.newSearchLegalDisclaimer ||
    locationPathname === AppRoutes.newSearchConsent ||
    locationPathname === AppRoutes.newSearchCompanyInfo

  const [broken, setBroken] = useState(false)
  const [toggled, setToggled] = useState(false)
  const collapsed = useAppSelector(selectIsNavBarCollapsed)

  useCheckSession()

  const { identifyUser } = useRudderStack()
  const permissions = useDisableBasedOnPermissions()
  dispatch(clearUserCredentials())

  const {
    isLoading: isLoadingUserData,
    data: userData,
    isPending,
    userFullname,
    userFirstLetter,
    aiReportCredits,
    aiAnalystReportCredits,
    adverseMediaReportCredits,
    assistedReportCredits,
    facialMatchingCredits,
  } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      isPending: selectIsPending(data),
      userFullname: selectUserFullname(data),
      userFirstLetter: selectUserFirstLetter(data),
      aiReportCredits: selectAiReportCredits(data),
      aiAnalystReportCredits: selectAiAnalystReportCredits(data),
      adverseMediaReportCredits: selectAdverseMediaReportCredits(data),
      assistedReportCredits: selectAssistedReportCredits(data),
      facialMatchingCredits: selectFacialMatchingCredits(data),
      data,
    }),
  })

  const isCredits =
    assistedReportCredits > 0 &&
    aiAnalystReportCredits > 0 &&
    aiReportCredits > 0 &&
    adverseMediaReportCredits > 0 &&
    facialMatchingCredits > 0

  const {
    i18n: { changeLanguage },
  } = useTranslation()

  useEffect(() => {
    if (userData) {
      dispatch(setUserInfo(userData))
      changeLanguage(userData.language)
      identifyUser({
        userId: userData.id,
        enterpriseName: userData.enterprise.name,
        firstname: userData.firstname,
        lastname: userData.lastname,
        phoneNumber: userData.phoneNumber,
        role: userData.role,
        status: userData.status,
        email: userData.email,
        permissions: userData.permissions,
      })
    }
  }, [userData])

  if (isLoadingUserData || !userData) {
    return (
      <div className="flex h-screen items-center">
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="h-screen">
      <div className="flex w-full">
        <Sidebar
          toggled={toggled}
          width={'240px'}
          className={`w-full`}
          onBreakPoint={setBroken}
          onBackdropClick={() => setToggled(false)}
          breakPoint="md"
          collapsed={collapsed}
          backgroundColor={weCheckPalette.baseColor}
          style={{ borderColor: weCheckPalette.baseColorHighlight }}
        >
          <div
            className={`flex flex-col h-full w-full${
              collapsed ? 'items-center' : ''
            } ${broken ? 'py-0' : ''}`}
          >
            <div className="flex items-center h-14 p-4 bg-baseColorHighlight justify-between">
              <WecheckEnviromentDistinction enviroment={enviroment} />
              <div
                className={`${
                  locationPathname === AppRoutes.account && 'bg-primaryColor'
                } w-7 h-7 rounded-md flex items-center justify-center`}
              >
                <Link to={AppRoutes.account} role="button">
                  <Setting2
                    size="32"
                    color={
                      locationPathname === AppRoutes.account
                        ? weCheckPalette.staticWhite
                        : weCheckPalette.icon
                    }
                    variant="Bold"
                    className="p-1"
                  />
                </Link>
              </div>
            </div>
            <div className="flex flex-col flex-1  gap-3 p-4">
              <SidebarItem
                name={t('dashboard.sidebar.runSearch')}
                isActive={
                  locationPathname === AppRoutes.dashboard ||
                  isCreateReportFlow ||
                  locationPathname === AppRoutes.faceSearch
                }
                linkUrl={AppRoutes.dashboard}
                disabled={!permissions.handleReports}
                collapsed={collapsed}
              />
              <SidebarItem
                name={t('dashboard.sidebar.reportHistory')}
                isActive={
                  locationPathname === AppRoutes.reports ||
                  locationPathname === AppRoutes.yourReports ||
                  locationPathname === AppRoutes.FaceMatchResults ||
                  locationPathname === AppRoutes.reportGeneralInfo ||
                  locationPathname === AppRoutes.postsInsights ||
                  locationPathname === AppRoutes.postList
                }
                linkUrl={AppRoutes.reports}
                disabled={!permissions.viewReports}
                collapsed={collapsed}
              />

              <SidebarItem
                name={t('dashboard.sidebar.accountBalance')}
                isActive={locationPathname === AppRoutes.historyPlan}
                linkUrl={AppRoutes.historyPlan}
                disabled={!permissions.handleBilling}
                collapsed={collapsed}
              />
              <SidebarItem
                name={t('dashboard.sidebar.team')}
                isActive={locationPathname === AppRoutes.team}
                linkUrl={AppRoutes.team}
                disabled={!permissions.handleUsers}
                collapsed={collapsed}
              />
              <SidebarItem
                name={t('dashboard.sidebar.support')}
                isActive={locationPathname === AppRoutes.contactUs}
                linkUrl={AppRoutes.contactUs}
                disabled={false}
                collapsed={collapsed}
              />
            </div>

            <div className="flex flex-col gap-5 p-4 bg-baseColorHighlight">
              <SidebarUser
                mail={userData.email}
                name={userFullname}
                userInitials={userFirstLetter}
                isPending={isPending}
              />
              <div className="flex flex-col gap-3  w-full">
                {isCredits && (
                  <p className="text-sm font-medium tracking-wide">
                    {t('dashboard.sidebar.creditsBalance')}
                  </p>
                )}

                {assistedReportCredits > 0 && (
                  <SidebarCredits
                    credits={assistedReportCredits}
                    isLoading={isLoadingUserData}
                    text={t('dashboard.sidebar.weCheckAssisted')}
                    icon={<WeCheckAssistedCreditIcon width={20} />}
                  />
                )}
                {aiAnalystReportCredits > 0 && (
                  <SidebarCredits
                    credits={aiAnalystReportCredits}
                    isLoading={isLoadingUserData}
                    text={t('dashboard.sidebar.aIAnalystReport')}
                    icon={<AiPlusAnalistCreditIcon width={20} />}
                  />
                )}
                {aiReportCredits > 0 && (
                  <SidebarCredits
                    credits={aiReportCredits}
                    isLoading={isLoadingUserData}
                    text={t('dashboard.sidebar.aIReport')}
                    icon={<AiReportCreditIcon width={20} />}
                  />
                )}
                {adverseMediaReportCredits > 0 && (
                  <SidebarCredits
                    credits={adverseMediaReportCredits}
                    isLoading={isLoadingUserData}
                    text={t('dashboard.sidebar.adverseMedia')}
                    icon={<AdverseMediaCreditIcon width={20} />}
                  />
                )}
                {facialMatchingCredits > 0 && (
                  <SidebarCredits
                    credits={facialMatchingCredits}
                    isLoading={isLoadingUserData}
                    text={t('dashboard.sidebar.facialMatchingCredit')}
                    icon={<FacialMatchingCrediticon width={20} />}
                  />
                )}
              </div>

              <SidebarLogout />
            </div>
          </div>
        </Sidebar>
        <main className={`w-full h-screen lg:h-auto`}>
          {broken && !isCreateReportFlow && (
            <div className="p-4 flex justify-between sticky top-0 z-50 bg-transparent">
              <Button
                asChild
                size={'icon'}
                className="bg-baseColorHighlight"
                variant={'outline'}
                onClick={() => setToggled(!toggled)}
              >
                <div>
                  <Menu width={20} height={20} variant="Bold" />
                </div>
              </Button>
            </div>
          )}
          <div
            className={`relative ${
              !isCreateReportFlow && 'lg:px-8 lg:py-8 px-4 py-5'
            } w-full bg-transparent via-15% overflow-auto md:h-screen`}
          >
            <div
              className="fixed inset-0 bg-cover bg-no-repeat bg-center md:ml-[240px]"
              style={{
                backgroundImage: ` url(${backgroundImage1})`,
                zIndex: -1,
                height: '100%',
              }}
            ></div>
            <div
              className="absolute inset-0 bg-cover bg-no-repeat bg-center animate-bg-scroll"
              style={{
                backgroundImage: `url(${backgroundImage2})`,
                zIndex: -1,
                height: '100%',
              }}
            ></div>

            <div className="relative z-10 h-full">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
